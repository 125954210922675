import React, { useState } from "react";
import ReactDOM from "react-dom";
import Home from "./Home";
import "./styles.css";

var SHA512 = require("crypto-js/sha512");


function App() {
  // React States
  const [errorMessages, setErrorMessages] = useState({});
  const [isSubmitted, setIsSubmitted] = useState(false);


  const errors = {
    uname: "invalid username",
    pass: "invalid password"
  };

  const handleSubmit = (event) => {
    //Prevent page reload
    event.preventDefault();
    var {pass } = document.forms[0];
    // Compare user info
    if (SHA512(pass.value) != process.env.REACT_APP_PASSWORD) {
        // Invalid password
        setErrorMessages({ name: "pass", message: errors.pass });
    }
    else {
        setIsSubmitted(true);
    }
  };

  // Generate JSX code for error message
  const renderErrorMessage = (name) =>
      name === errorMessages.name && (
          <div className="error">{errorMessages.message}</div>
      );

  // JSX code for login form
  const renderForm = (
      <div className="app">
      <div className="login-form">
          <div className="title">Sign In</div>
      <div className="form">
        <form onSubmit={handleSubmit}>
          <div className="input-container">
            <label>Password </label>
            <input type="password" name="pass" required />
            {renderErrorMessage("pass")}
          </div>
          <div className="button-container">
            <input type="submit" />
          </div>
        </form>
      </div>
      </div>
      </div>
  );
  return (
          <div>
          {isSubmitted ? <Home /> : renderForm}
          </div>
  );
}

export default App;