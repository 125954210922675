import React from "react";
import {useState} from "react";
import Stack from '@mui/material/Stack';     
import Button from '@mui/material/Button';
import Sjekkliste from '../sjekkliste.pdf';
import BrukerManual from '../manual.pdf';
import Kurs from '../fotokite_kurs.pptx';
import FotokiteLog from "./FotokiteLog";

function Fotokite(){
    const [fotoState, setFotoState] = useState('FotoMain')



    function RenderFotoMain(){
        return (
            <div>
                <Stack spacing={2} direction = "column">
                    <Button variant="text" size="large" onClick={()=> setFotoState("Logg")}>Logg og Rapportering</Button>
                    <Button variant="text" size="large" target="_blank" href = {Sjekkliste} onClick={()=> setFotoState("Sjekk")}>Sjekkliste</Button>
                    {/*<Button variant="text" size="large" onClick={()=> setFotoState("Operasjon")}>Operasjonsmanual</Button>*/}
                    <Button variant="text" size="large" target="_blank" href={BrukerManual} onClick={()=> setFotoState("Bruker")}>Brukermanual</Button>
                    <Button variant="text" size="large" target="_blank" href={Kurs} onClick={()=> setFotoState("Kurs")}>Fotokite Kurs</Button>
                </Stack>
            </div>
        )
    }

    return(
        <div className="Fotokite">
          {(() => {
            switch (fotoState) {
                case 'FotoMain':
                  return <RenderFotoMain />
                case 'Logg':
                  return (
                      <div>
                          <Stack spacing={2} direction="column">
                              <FotokiteLog />
                              <Button variant="contained" size="large" onClick={()=> setFotoState("FotoMain")}>Tilbake</Button>
                          </Stack>
                      </div>
                  );
                case 'Sjekk':
                  return setFotoState("FotoMain");
                case 'Operasjon':
                  return setFotoState("FotoMain");
                case 'Bruker':
                    return setFotoState("FotoMain");
                case 'Learning':
                    return setFotoState("FotoMain");
                default:
                  return setFotoState("FotoMain")
              }
              })()}
        </div>
    );
}

export default Fotokite