import {useState} from "react";
import React from 'react';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Fotokite from "./components/Fotokite";

const drones = ['Fotokite']

function Home() {
    const [state, setState] = useState('Main')

    function RenderMain() {
        const listItems = drones.map(
            (element) => {
                return (
                    <Button variant="contained" size="large" key={element} onClick={() => setState(element)} >{element}</Button>
                )

            }
        )
        return (
            <div>
                <Stack spacing={2} direction="column">
                    {listItems}
                </Stack>
            </div>
        )
    }
    return (
        <div className="App">
            {(() => {
                switch (state) {
                    case 'Main':
                        return <div>
                            <RenderMain />
                        </div>
                    case 'Fotokite':
                        return <div>
                            <Stack spacing={2} direction="column">
                                <Fotokite />
                                <Button variant="contained" size="large" onClick={() => setState("Main")}>Hjem</Button>
                            </Stack>
                        </div>
                    default:
                        return setState('Main')
                }
            })()}
        </div>
    );
}

export default Home;