export const surveyJson = {
    "locale": "no",
    "title": {
        "no": "Fotokite loggføring",
        "default": "Log fotokite flight"
    },
    "description": {
        "default": "Log and report fotokite flight",
        "no": "Logg og rapportering for Fotokite"
    },
    "logoPosition": "right",
    "pages": [
        {
            "name": "page1",
            "elements": [
                {
                    "type": "text",
                    "name": "takeoff",
                    "title": {
                        "default": "Take-off position",
                        "no": "Take-off og landings-posisjon"
                    }
                },
                {
                    "type": "text",
                    "name": "date",
                    "title": {
                        "no": "Dato",
                        "default": "Date"
                    },
                    "inputType": "date"
                },
                {
                    "type": "text",
                    "name": "time-takeoff",
                    "title": {
                        "no": "Tidspunkt for take-off",
                        "default": "Time for take-off"
                    },
                    "inputType": "time"
                },
                {
                    "type": "text",
                    "name": "time-landing",
                    "title": {
                        "no": "Tidspunkt for landing",
                        "default": "Time for landing"
                    },
                    "inputType": "time"
                },
                {
                    "type": "text",
                    "name": "wheather",
                    "title": {
                        "no": "Vær",
                        "default": "Wheather"
                    }
                },
                {
                    "type": "text",
                    "name": "teammembers",
                    "title": {
                        "no": "Deltakere i flygning",
                        "default": "Team members"
                    }
                },
                {
                    "type": "radiogroup",
                    "name": "checklist",
                    "title": {
                        "no": "Fullført sjekkliste?",
                        "default": "Checklist completed?"
                    },
                    "choices": [
                        {
                            "value": "Fullført",
                            "text": {
                                "default": "Completed",
                                "no": "Fullført"
                            }
                        },
                        {
                            "value": "Ikke fullført",
                            "text": "Not completed"
                        }
                    ]
                }
            ],
            "title": {
                "no": "Logg Fotokite flygning",
                "default": "Log fotokite flight"
            }
        }
    ],
    "pagePrevText": {
        "no": "Forrige"
    },
    "pageNextText": {
        "no": "Neste"
    },
    "completeText": {
        "no": "Fullfør"
    },
    "previewText": {
        "no": "Fullfør"
    },
    "editText": {
        "no": "Endre"
    },
    "widthMode": "responsive",
    completedHtml: "<p><h4>Takk for at du logget flighten din. Slik svarte du:</h4></p><p> Take-off og landings-posisjon: <b>{takeoff}</b></p><p>Dato: <b>{date}</b></p><p>Tidspunkt for flygning:</p><p>{time-takeoff}:{time-landing}</p><p>Vær: </p><p>{wheather}</p><p>Deltakere i flygning: </p><p>{teammembers}</p> "
};