import React from "react";
import {useState, useMemo} from "react";
import 'survey-core/defaultV2.min.css';
import { StylesManager, Model } from 'survey-core';
import "survey-core/defaultV2.css";
import { Survey } from 'survey-react-ui';
import { ref, push } from "firebase/database";
import { db } from "../../src/utils/database";
import {surveyJson} from "./surveyJSON";
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';



function FotokiteLog(){
    const [surveyState, setSurveyState] = useState(false);
    const query = ref(db, 'Fotokite');
    StylesManager.applyTheme("defaultV2");
    const survey = useMemo(() => new Model(surveyJson), []);

    function retakeSurvey() {
        setSurveyState(false)
        survey.clear();
        survey.render();
    }


    survey.onComplete.add((sender) => {
        push(query,JSON.stringify(sender.data))
        setSurveyState(true)
        console.log(surveyState)
    })



    return(
        <div>
            <Survey model={survey} />
            <Stack spacing={2} direction="column">
                { surveyState ? <Button variant="contained" size="large" onClick={() => retakeSurvey()}>Log another flight</Button> : null }
            </Stack>
        </div>

    );
}

export default FotokiteLog